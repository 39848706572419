<template>
  <modal :title="title" :id="id" :actionButtons="false" size="xl" :closeButton="true">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <h2>Add Links</h2>

        </div>
        <div class="col-12">

          <h2>Add Social Links</h2>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import Modal from "@/components/Modal.vue";
export default {
  name: "InitialSetupBooLink",
  props: ['id', 'model'],
  components: {
    Modal,
  },
  data() {
    return {
      title: 'Initial Setup Boolink',
      hideForm: false,
    };
  },
  computed: {
  },
  watch: {
  },
  created() {
  },
  methods: {
  }
};
</script>
<style>
video{
  height: auto;
}
</style>
