<template>
    <div>
        <nav
            class="navbar navbar-expand-md navbar-light top-navbar"
            id="topbar-content"
        >
            <div class="container-fluid">
                <nav aria-label="breadcrumb">
                    <ol class="breadcrumb">
                        <li class="breadcrumb-item"><a href="#" class="navbar-brand">Dashboard</a></li>
                        <li class="breadcrumb-item active d-flex justify-content-center align-items-center " aria-current="page">Links</li>
                    </ol>
                </nav>
            </div>
        </nav>
        <div class="container">
            <button
                @click="addLink"
                type="button"
                class="btn btn-primary btn-block mb-5"
            >
                Add New Link
            </button>

            <draggable
                v-model="links"
                :options="{ animation: 350 }"
                handle=".handler"
            >
                <div
                    class="card mb-3"
                    v-for="(link, index) in links"
                    :key="index"
                >
                    <div class="card-body m-0 p-1">
                        <div class="row">
                            <div class="col-auto handler">
                                <span
                                    class="fe fe-more-vertical text-muted"
                                    style="font-size: 22px"
                                ></span>
                            </div>
                            <div class="col content">
                                <div class="row">
                                    <div class="col">
                                        <div class="title">
                                            <input
                                                v-model="link.title"
                                                @keyup="patchLink(link, index)"
                                                type="text"
                                                class="form-control form-control-flush"
                                                placeholder="Enter Title"
                                            />
                                        </div>
                                        <input
                                            v-model="link.link"
                                            @keyup="patchLink(link, index)"
                                            type="text"
                                            class="form-control form-control-flush"
                                            placeholder="http://url"
                                        />
                                    </div>
                                    <div
                                        class="col-auto d-flex flex-column align-items-end"
                                    >
                                        <button
                                            @click="(link.active = !link.active); patchLink(link, index);"
                                            class="btn btn-rounded-circle btn-white border-0 mb-2"
                                        >
                                            <span
                                                class="fe fe-eye"
                                                v-if="link.active"
                                            ></span>
                                            <span
                                                class="fe fe-eye-off"
                                                v-else
                                            ></span>
                                        </button>
                                        <confirmable-button
                                            @confirm="removeLink(link)"
                                        >
                                            <i class="fe fe-trash-2"></i>
                                        </confirmable-button>
                                    </div>
                                </div>
                                <!-- <div class="row">
                                    <div class="col">
                                        <span
                                            class="fe fe-film mr-4"
                                        ></span>
                                        <span
                                            class="fe fe-headphones mr-4"
                                        ></span>
                                        <span
                                            class="fe fe-paperclip mr-4"
                                        ></span>
                                        <span
                                            class="fe fe-cast mr-4"
                                        ></span>
                                        <span
                                            class="fe fe-star mr-4"
                                        ></span>
                                    </div>
                                    <div class="col-auto"></div>
                                </div> -->
                            </div>
                        </div>
                    </div>

                </div>
            </draggable>
        </div>
        <initial-modal id="initialSetupModal"></initial-modal>
    </div>
</template>
<script>
import { mapState, mapActions, mapGetters } from "vuex";
import draggable from "vuedraggable";
import ConfirmableButton from "@/components/ConfirmableButton";
import InitialModal from "@/components/InitialModal";
import { modalMixins } from '@/mixins/modal.js'
import debounce from 'lodash/debounce';

export default {
    mixins: [modalMixins],
    components: {
        draggable,
        ConfirmableButton,
        InitialModal
    },

    data() {
        return {
            params: {
                query: {
                    userId: '',
                    $limit: 1000,
                    $sort: { priority: 1, createdAt: -1 },
                    type: { $in: ['link'] },
                },
            }
        };
    },
    created() {
        //this.params.query.userId = this.user.userId;
        //this.find(this.params)
    },
    computed: {
        ...mapState('auth', {
            user: 'payload',
        }),
        ...mapState('links', {
            isUserLoading: 'isFindPending',
        }),
        ...mapGetters('links', {
            findLinksInStore: 'find',
        }),
        links: {
            set(value) {
                value.forEach((item, index) => {
                    item.priority = index;
                    this.patch([item._id, item])
                });
            },
            get() {
                if (!this.user) return;
                this.params.query.userId = this.user.userId;
                return this.findLinksInStore(this.params).data;
            }
        },
    },

    methods: {
        ...mapActions('links', ['find', 'create', 'patch', 'remove']),
		patchLink: debounce(function (link, index) {
            if (!link._id) return;
            this.errors = null;
            let data = {
                title: link.title,
                link: link.link,
                active: link.active,
                type: 'link',
                userId: this.user.userId,
            }
            this.patch([link._id, data, {}])
                .then(() => {
                    this.$toasted.global.success({
                        message: this.$t("Link updated"),
                    });
                })
                .catch((errors) => {
                    this.$toasted.global.error({
                        message: this.$t(errors.message),
                    });
                    console.log(errors);
                    this.errors = errors.errors;
                });
        }, 1000),
        addLink() {
            this.errors = null;
            let data = {
                title: 'Your Link',
                link: 'https://',
                type: 'link',
                userId: this.user.userId,
            }
            this.create([data])
                .then( () =>{
                    // this.$toasted.global.success({
                    //     message: 'Link Added',
                    // });
                })
                .catch((errors) => {
                    this.$toasted.global.error({
                      message: this.$t(errors.message),
                    });
                    console.log(errors);
                    this.errors = errors.errors;
                });
        },
        removeLink(link) {
            this.errors = null;
            this.remove([link._id])
                .then( () =>{
                    this.$toasted.global.success({
                        message: 'Link Removed',
                    });
                })
                .catch((errors) => {
                    this.$toasted.global.error({
                      message: this.$t(errors.message),
                    });
                    console.log(errors);
                    this.errors = errors.errors;
                });
        },
    },
};
</script>

<style lang="scss">
.handler {
    cursor: move;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    left: 10px;
}
.top-navbar {
    height: 70px;
    margin-bottom: 30px;
}
</style>
