<template>
    <b-modal
        :id="id"
        :ref="ref"
        title="Add an appointment"
        :hideFooter="false"
        :scrollable="scrollable"
        centered
        lazy
        :size="size"
        :no-close-on-esc="false"
        @hidden="$emit('hidden')"
        @shown="$emit('shown')"
        tabindex="0"
        :no-enforce-focus="true"
        :dialog-class="dialogClass"
    >
        <template slot="modal-header">
            <slot name="modal-header">
                <div class="row no-gutters align-items-center w-100">
                    <div class="col">
                        <h4 class="card-header-title">{{ title }}</h4>
                    </div>
                    <div class="col-auto" v-if="subtitle">
                        <h2 class="card-header-title text-muted">
                            {{ subtitle }}
                        </h2>
                    </div>
                    <div class="col-auto">
                        <button
                            type="button"
                            class="close"
                            aria-label="Close"
                            @click="close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                </div>
            </slot>
        </template>

        <template slot="modal-footer">
            <slot name="modal-footer">
                <div
                    class=""
                    style="display: block"
                    v-if="actionButtons"
                >
                    <div class="row">
                        <div class="col-auto">
                            <slot name="modal-footer-actions"></slot>
                        </div>
                        <div class="col">
                            <button
                                type="button"
                                @click="confirmDelete()"
                                v-if="deleteAction"
                                aria-label="Delete"
                                class="btn btn-danger mr-2 btn-sm"
                            >
                                {{ $t("Delete") }}
                            </button>
                        </div>
                        <div class="col-auto">
                            <button
                                type="button"
                                @click="close"
                                aria-label="Close"
                                class="btn btn-white mr-2 btn-sm"
                            >
                                {{ $t("Cancel") }}
                            </button>
                            <button
                                type="button"
                                :disabled="saveDisabled"
                                @click="delayedSave"
                                v-if="saveAction"
                                aria-label="Close"
                                class="btn btn-primary mr-2"
                            >
                                <div v-if="!saveDisabled">
                                    {{ $t("Save") }}
                                </div>
                                <div
                                    v-else
                                    class="spinner-border spinner-grow-sm"
                                >
                                    <span class="sr-only">Loading...</span>
                                </div>
                            </button>
                            <button
                                type="button"
                                @click="okAction"
                                v-if="okAction"
                                aria-label="Close"
                                class="btn btn-primary mr-2 btn-sm"
                            >
                                {{ "Ok" }}
                            </button>
                        </div>
                    </div>
                </div>
                <div class="container text-right" v-if="closeButton">
                    <button
                        type="button"
                        @click="close"
                        aria-label="Close"
                        class="btn btn-white btn-sm"
                    >
                        {{ $t("Close") }}
                    </button>
                </div>
            </slot>
        </template>
        <slot></slot>
    </b-modal>
</template>

<script>
export default {
    props: [
        "okAction",
        "scrollable",
        "title",
        "subtitle",
        "id",
        "actionButtons",
        "closeButton",
        "prop",
        "saveAction",
        "size",
        "deleteAction",
        "model",
        "dialogClass",
    ],

    data() {
        return {
            saveDisabled: false,
            timeout: null,
            ref: "customModal",
        };
    },

    created() {},
    methods: {
        close() {
            this.$root.$emit("bv::hide::modal", this.id);
        },
        hideModal() {},
        confirmDelete() {
            if (window.confirm("Do you confirm ?")) {
                this.deleteAction();
                this.close();
            }
        },
        delayedSave() {
            this.saveDisabled = true;
            this.timeout = setTimeout(() => {
                this.saveDisabled = false;
            }, 1000);
            this.saveAction();
        },
    },
    components: {},
};
</script>


<style lang="scss" scoped>
header,
footer {
    border: 0 !important;
    padding: 0 !important;
    margin: 0 !important;
}

footer {
    display: block !important;
}
.disabled {
    color: #ccc !important;
}
.card-footer {
    display: flex;
    align-items: flex-end;
    flex-direction: row-reverse;
}
.modal-full {
    max-width: 100%;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
}

.modal-full .modal-content {
    height: auto;
    min-height: 100%;
    border-radius: 0;
}
.modal-full .close {
    font-size: 4rem;
    font-weight: normal;
}
.fullHeight {
    height: 80vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.fullHeightWithTitle {
    height: 70vh;
    overflow-x: hidden;
    overflow-y: auto;
}

.fullHeightWithTitleAndNav {
    height: 58vh;
    overflow-y: auto;
    overflow-x: hidden;
}
</style>
